import React, { useState, useEffect, useRef } from 'react';
import { FaInstagram, FaBars, FaHome, FaInfoCircle, FaComments, FaAddressBook, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import './home.css';
import Mandy from '../../assets/mandy-bg.png';
import StarTrail from '../../components/StarTrail';
import '../../components/StarTrail.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Image1 from '../../assets/image1.png';
import Image2 from '../../assets/image2.png';
import Image3 from '../../assets/image3.png';
import Image4 from '../../assets/image4.png';
import Image5 from '../../assets/image5.png';
import Image6 from '../../assets/image6.png';
import Image7 from '../../assets/image7.png';
import FeedbackVideo from '../../assets/feedback.mp4'; // Caminho do vídeo de feedback

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showOrderButton, setShowOrderButton] = useState(false);
  const feedbackRef = useRef(null);
  const videoRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const createHeart = (event) => {
      const heart = document.createElement('div');
      heart.className = 'heart';
      document.body.appendChild(heart);

      heart.style.left = `${event.pageX}px`;
      heart.style.top = `${event.pageY}px`;

      setTimeout(() => {
        heart.remove();
      }, 1000);
    };

    document.addEventListener('mousemove', createHeart);

    return () => {
      document.removeEventListener('mousemove', createHeart);
    };
  }, []);

  const handleVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowOrderButton(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (feedbackRef.current) {
      observer.observe(feedbackRef.current);
    }

    return () => {
      if (feedbackRef.current) {
        observer.unobserve(feedbackRef.current);
      }
    };
  }, [feedbackRef]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="min-h-screen bg-e0f7fa relative overflow-hidden">
      <StarTrail />
      <header className="header flex justify-between items-center p-4 shadow relative z-10">
        <div className="flex space-x-4 items-center">
          <span className="hidden md:inline font-bold text-lg" style={{ fontFamily: 'Arial, sans-serif' }}>Instagram</span>
          <a href="https://www.instagram.com/personalizadosmandy" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-200">
            <FaInstagram size={24} />
          </a>
        </div>
        <div className="md:hidden flex items-center space-x-4">
          <button onClick={toggleMenu} className="text-white hover:text-gray-200 focus:outline-none">
            <FaBars size={24} />
          </button>
          {isOpen && (
            <nav className="absolute right-4 top-16 flex flex-col space-y-2 bg-white shadow p-4 rounded-lg z-50">
              <button onClick={() => handleMenuClick('HOME')} className="text-gray-700 hover:bg-blue-100 hover:text-gray-900 rounded-2xl p-1 flex items-center justify-between">
                HOME
              </button>
              <button onClick={() => handleMenuClick('QUEM SOMOS')} className="text-gray-700 hover:bg-blue-100 hover:text-gray-900 rounded-2xl p-1 flex items-center justify-between">
                QUEM SOMOS
              </button>
              <button onClick={() => handleMenuClick('SOBRE')} className="text-gray-700 hover:bg-blue-100 hover:text-gray-900 rounded-2xl p-1 flex items-center justify-between">
                SOBRE
              </button>
              <button onClick={() => handleMenuClick('FEEDBACKS')} className="text-gray-700 hover:bg-blue-100 hover:text-gray-900 rounded-2xl p-1 flex items-center justify-between">
                FEEDBACKS
              </button>
              <button onClick={() => handleMenuClick('CONTATO')} className="text-gray-700 hover:bg-blue-100 hover:text-gray-900 rounded-2xl p-1 flex items-center justify-between">
                CONTATO
              </button>
            </nav>
          )}
        </div>
        <nav className="hidden md:flex items-center space-x-6">
          <a href="#HOME" className="text-white hover:bg-gray-200 hover:text-gray-900 rounded-2xl px-2 py-1 flex items-center justify-between">
            <FaHome className="mr-2" /> HOME
          </a>
          <span className="text-white">|</span>
          <a href="#QUEM SOMOS" className="text-white hover:bg-gray-200 hover:text-gray-900 rounded-2xl px-2 py-1 flex items-center justify-between">
            <FaInfoCircle className="mr-2" /> QUEM SOMOS
          </a>
          <span className="text-white">|</span>
          <a href="#SOBRE" className="text-white hover:bg-gray-200 hover:text-gray-900 rounded-2xl px-2 py-1 flex items-center justify-between">
            <FaComments className="mr-2" /> SOBRE
          </a>
          <span className="text-white">|</span>
          <a href="#FEEDBACKS" className="text-white hover:bg-gray-200 hover:text-gray-900 rounded-2xl px-2 py-1 flex items-center justify-between">
            <FaAddressBook className="mr-2" /> FEEDBACKS
          </a>
          <span className="text-white">|</span>
          <a href="#CONTATO" className="text-white hover:bg-gray-200 hover:text-gray-900 rounded-2xl px-2 py-1 flex items-center justify-between">
            <FaEnvelope className="mr-2" /> CONTATO
          </a>
        </nav>
      </header>

      <main className="flex flex-col items-center justify-center p-4 mt-0 relative z-10">
        <img src={Mandy} alt="Mandy" className="logo w-1/4 rounded-full shadow-lg mb-4" />
        <p className="subtitle text-2xl text-gray-700 mb-8">Personalizados Criativos e Encadernação</p>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          arrows={true}
          className="w-full mb-12"
        >
          <div className="p-4">
            <img src={Image1} alt="Imagem 1" className="carousel-image rounded-lg shadow-lg" />
          </div>
          <div className="p-4">
            <img src={Image2} alt="Imagem 2" className="carousel-image rounded-lg shadow-lg" />
          </div>
          <div className="p-4">
            <img src={Image3} alt="Imagem 3" className="carousel-image rounded-lg shadow-lg" />
          </div>
          <div className="p-4">
            <img src={Image4} alt="Imagem 4" className="carousel-image rounded-lg shadow-lg" />
          </div>
          <div className="p-4">
            <img src={Image5} alt="Imagem 5" className="carousel-image rounded-lg shadow-lg" />
          </div>
          <div className="p-4">
            <img src={Image6} alt="Imagem 6" className="carousel-image rounded-lg shadow-lg" />
          </div>
          <div className="p-4">
            <img src={Image7} alt="Imagem 7" className="carousel-image rounded-lg shadow-lg" />
          </div>
        </Carousel>
        <div id="QUEM SOMOS" className="section who-we-are text-center mb-32 w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg">
          <h2 className="section-title text-4xl font-bold mb-4">Quem Somos</h2>
          <p className="text-lg text-gray-700 mx-auto">
            <span className="emoji">✨️</span>Transformamos ideias em realidade!
          </p>
          <p className="text-lg text-gray-700 mx-auto">
            <span className="emoji">🎁</span>Presentes encantadores para todas as ocasiões
          </p>
          <p className="text-lg text-gray-700 mx-auto">
            <span className="emoji">🌍</span>De Francisco Morato para todo o Brasil
          </p>
          <p className="text-lg text-gray-700 mx-auto">
            <span className="emoji">🛍</span>Explore e compre aqui!
          </p>
        </div>
        <div id="SOBRE" className="section sobre text-center mb-32 w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg">
          <h2 className="section-title text-4xl font-bold mb-4">Sobre</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            Desde 2023, a Personalizados Mandy tem se dedicado a criar produtos personalizados de alta qualidade. 
            Nosso compromisso é transformar ideias em produtos únicos, proporcionando experiências inesquecíveis para nossos clientes.
            De canecas a cadernos personalizados, garantimos que cada produto seja feito com cuidado e atenção aos detalhes.
            Nossa equipe trabalha diligentemente para garantir um serviço rápido e eficiente, entregando felicidade de Francisco Morato para todo o Brasil.
          </p>
        </div>
        <div id="FEEDBACKS" className="section text-center mb-16" ref={feedbackRef}>
          <h2 className="section-title text-4xl font-bold mb-4">Feedbacks</h2>
          <div className="mini-phone mx-auto">
            <div className="camera"></div>
            <video
              src={FeedbackVideo}
              controls
              autoPlay
              loop
              muted
              ref={videoRef}
              onClick={handleVideoClick}
              controlsList="nodownload nofullscreen noremoteplayback"
              disablePictureInPicture
            ></video>
          </div>
        </div>
        <div id="CONTATO" className="section text-center mb-16">
          <h2 className="section-title text-4xl font-bold mb-4">Contato</h2>
          <p className="text-lg text-gray-700 mb-4">
            <FaInstagram size={20} className="inline-block mr-2 text-pink-700" />
            <a href="https://www.instagram.com/personalizadosmandy" className="text-pink-700 hover:text-pink-900" target="_blank" rel="noopener noreferrer">@personalizadosmandy</a>
          </p>
          <p className="text-lg text-gray-700">
            <FaWhatsapp size={20} className="inline-block mr-2 text-green-400" />
            <a href="https://api.whatsapp.com/send?phone=+5511971659795&text=Ol%C3%A1,%20eu%20vim%20atrav%C3%A9s%20do%20seu%20site%20e%20gostaria%20de%20falar%20com%20você" className="text-green-400 hover:text-green-600">Whatsapp</a>
          </p>
        </div>
      </main>

      <footer className="footer p-4 shadow text-center text-white">
        <p>&copy; 2024 Personalizados Mandy. Todos os direitos reservados.</p>
      </footer>

      {/* Ícone flutuante do WhatsApp */}
      {showOrderButton && (
        <a 
          href="https://api.whatsapp.com/send?phone=+5511971659795&text=Ol%C3%A1,%20eu%20vim%20atrav%C3%A9s%20do%20seu%20site%20e%20gostaria%20de%20falar%20com%20você"
          className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg flex items-center space-x-2 hover:bg-green-600 transition z-50"
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FaWhatsapp size={24} />
          <span className="hidden md:inline">Faça um pedido agora mesmo</span>
        </a>
      )}
    </div>
  );
};

export default Home;
