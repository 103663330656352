// src/components/StarTrail.js
import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';

const StarTrail = () => {
  useEffect(() => {
    const createHeart = (x, y) => {
      const heart = document.createElement('div');
      heart.classList.add('heart');
      heart.style.left = `${x}px`;
      heart.style.top = `${y}px`;
      document.body.appendChild(heart);

      anime({
        targets: heart,
        opacity: [
          { value: 1, duration: 200 },
          { value: 0, duration: 1000 },
        ],
        translateY: [
          { value: -50, duration: 1200 },
        ],
        easing: 'easeOutQuad',
        complete: () => heart.remove(),
      });
    };

    const handleMouseMove = (e) => {
      const x = e.clientX + window.scrollX;
      const y = e.clientY + window.scrollY;
      createHeart(x, y);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return null;
};

export default StarTrail;
